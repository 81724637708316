
import Aboutus from './components/aboutus';
import Careers from './components/careers';
import Footer from './components/footer';
import Home from './components/home';
import NavBar from './components/navbar';
import Technology from './components/technology';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'



function App() {
  return (
    <>
      <Router>
          <NavBar/>
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/home' element={<Home/>} />
            <Route path='/aboutus' element={<Aboutus/>}/>
            <Route path='/careers' element={<Careers/>}/>
            <Route path='/technology' element={<Technology/>}/>
          </Routes>
          <Footer/>
      </Router>

    </>
  );
}

export default App;
