import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Background = styled('div')({
    backgroundColor: '#110945',
    height: '100%',
    position: 'relative',
});

const careers = [
    {
        title: 'Sales Head',
        description: `We are seeking a dynamic and experienced Sales Head. The individual will be responsible for developing and implementing sales strategies, building relationships with key clients, and driving revenue growth. You will oversee the sales process, manage the sales team, and ensure the achievement of sales targets.`,
        requirements: [
            'Proven experience as a Sales Head or similar leadership role',
            'Extensive knowledge of sales principles and practices',
            'Strong leadership and team management skills',
            'Excellent communication and negotiation skills',
            'Ability to analyze sales metrics and data to drive decision-making',
            'Experience in the tech or biomedical sector is a plus',
            'Bachelor’s degree in Business Administration, Marketing, or related field; MBA preferred'
        ]
    },
    {
        title: 'Operations Head',
        description: `We are looking for a skilled Operations Head to oversee our company's operations and procedures. The Operations Head will be responsible for ensuring operational efficiency, managing daily operations, and implementing business strategies to improve productivity and performance.`,
        requirements: [
            'Proven experience as an Operations Head or similar leadership role',
            'Strong organizational and leadership skills',
            'Experience in strategic planning and execution',
            'Knowledge of business processes and functions (finance, HR, procurement, etc.)',
            'Excellent communication and problem-solving skills',
            'Experience in the tech or biomedical sector is a plus',
            'Bachelor’s degree in Business Administration or related field; MBA preferred'
        ]
    },
    {
        title: 'Micro and Nano Electronics Engineer',
        description: `We are seeking a talented Micro and Nano Electronics Engineer to join our team. The ideal candidate will have experience in designing and developing micro and nano electronic devices, with a focus on nanofluidics and nanoelectronics applications.`,
        requirements: [
            'Proven experience in micro and nanoelectronics engineering',
            'Strong knowledge of semiconductor devices and nanotechnology',
            'Experience with design and simulation software',
            'Excellent problem-solving and analytical skills',
            'Ability to work in a multidisciplinary team',
            'Master’s degree in Electrical Engineering, Electronics Engineering, or related field'
        ]
    },
    {
        title: 'Mechanical Design Engineer',
        description: `We are looking for a creative and detail-oriented Mechanical Design Engineer to join our team. The Mechanical Design Engineer will be responsible for designing, developing, and testing mechanical systems and components, with a focus on biomedical and nanotechnology applications.`,
        requirements: [
            'Proven experience in mechanical design engineering',
            'Proficiency in CAD software (SolidWorks, AutoCAD, Ansys)',
            'Strong knowledge of materials and manufacturing processes',
            'Excellent problem-solving and analytical skills',
            'Ability to work collaboratively in a team environment',
            'Bachelor’s or Master’s degree in Mechanical Engineering or related field'
        ]
    },
    {
        title: 'Finance Head',
        description: `We are seeking an experienced Finance Head to manage our financial operations. The Finance Head will be responsible for financial planning, budgeting, forecasting, and reporting, as well as overseeing accounting operations and ensuring compliance with financial regulations.`,
        requirements: [
            'Proven experience as a Finance Head or similar role',
            'Strong knowledge of financial management and accounting principles',
            'Excellent analytical and strategic thinking skills',
            'Proficiency in financial software and tools',
            'Strong leadership and communication skills',
            'Bachelor’s degree in Finance, Accounting, or related field; CPA or MBA preferred'
        ]
    },
    {
        title: 'Software Developer',
        description: `We are looking for a talented Software Developer to join our team. The ideal candidate will have experience in developing software applications and systems, with a focus on integrating with biomedical and nanotechnology hardware.`,
        requirements: [
            'Proven experience as a Software Developer',
            'Proficiency in programming languages such as Python, C++, or Java',
            'Experience with software development frameworks and tools',
            'Strong problem-solving and debugging skills',
            'Ability to work in a multidisciplinary team',
            'Master’s degree in Computer Science, Software Engineering, or related field'
        ]
    }
];

const Careers = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/aboutus#contact-form');
    };
    return (
        <Background>
            <Box sx={{ paddingTop: 4, paddingBottom: 4, paddingRight: { xs: 4, md: 8 }, paddingLeft: { xs: 4, md: 8 } }}>
                <Typography variant="h5" sx={{ color: "white", fontSize: { xs: "20px", md: "30px" } }} gutterBottom>
                    Join Us on This Journey
                </Typography>
                <Typography variant="body1" paragraph sx={{ color: "white", fontSize: { xs: "15px", md: "20px" } }}>
                    Natulatech Solutions isn’t just a company; it's a movement. We’re a collective of thinkers, dreamers, and doers, bound by the belief that the best way forward is by looking back to nature. Whether you’re an industry leader, a fellow innovator, or someone who cares deeply about the future of our planet, we invite you to be part of our journey.
                </Typography>

                {careers.map((job, index) => (
                    <Paper key={index} elevation={3} sx={{ margin: 2, padding: 2, backgroundColor: "#cedbd7" }}>
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", fontSize: { xs: "18px", md: "20px" } }}>
                            {job.title}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <strong>Job Description:</strong><br />
                            {job.description}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <strong>Requirements:</strong>
                            <ul>
                                {job.requirements.map((req, idx) => (
                                    <li key={idx}>{req}</li>
                                ))}
                            </ul>
                        </Typography>

                        <Button variant="contained" sx={{
                            backgroundColor: "#110945", '&:hover': {
                                backgroundColor: "darkgreen",
                            },
                        }} onClick={handleClick}>
                            APPLY NOW
                        </Button>

                    </Paper>
                ))}
            </Box>
        </Background>
    );
};

export default Careers;
