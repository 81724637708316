import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import BoxComponent from './boxcomponent';

const Background = styled('div')({
    backgroundImage: 'url(https://res.cloudinary.com/digfyljcv/image/upload/v1716116394/website%20pics/Designer_cbcdhi.png)', // Set the path to your background image
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "center",
    height: '100%',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the color and opacity as needed
      zIndex: 1,
    },
    '> *': {
      position: 'relative',
      zIndex: 2,
    },
  });

const Home = () => {
    return (
        <>
            <Background>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container sx={{ height: '90vh' }}>

                        <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 1 }, color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body1" sx={{ fontSize: { xs: "25px", md: "50px" } }}>NATULATECH (n):</Typography>
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 2 } }}>
                            <Grid container sx={{ height: '100%' }}>
                                <Grid item xs={6} sx={{ border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ color: "white", fontSize: { xs: "25px", md: "50px" } }}>α</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ color: "white", fontSize: { xs: "25px", md: "50px" } }}>β</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ color: "white", fontSize: { xs: "25px", md: "50px" } }}>γ</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ color: "white", fontSize: { xs: "25px", md: "50px" } }}>δ</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ order: { xs: 4, md: 3 } }}>
                            <Grid container sx={{ height: '100%' }}>
                                <Grid item xs={6} sx={{ border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ color: "white", fontSize: { xs: "25px", md: "50px" } }}>ϕ</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ color: "white", fontSize: { xs: "25px", md: "50px" } }}>χ</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ color: "white", fontSize: { xs: "25px", md: "50px" } }}>ψ</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ border: '1px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography sx={{ color: "white", fontSize: { xs: "25px", md: "50px" } }}>ω</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ textAlign: "center", order: { xs: 3, md: 4 }, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body1" sx={{ padding: "15px", color: "white", fontSize: { xs: "18px", md: "25px" } }} >Innovative technologies that emulate nature to provide sustainable solutions for pressing global challenges.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Background>
            <BoxComponent />
        </>

    );
}

export default Home;