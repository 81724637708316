import React, { useState, useEffect } from 'react'
import { Typography, TextField, Button, Box, Grid, List, ListItem, ListItemIcon, Alert, Collapse, Link, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import emailjs from '@emailjs/browser';
import { useLocation } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Background = styled('div')({
    backgroundImage: 'url(https://res.cloudinary.com/digfyljcv/image/upload/v1716309228/website%20pics/Bloom_BG_yescvh.png)', // Set the path to your background image
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "fixed",
    height: '100%',
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adjust the color and opacity as needed
        zIndex: 1,
    },
    '> *': {
        position: 'relative',
        zIndex: 2,
    },
});

const StyledTextField = styled(TextField)({
    marginBottom: '20px',
    width: '100%',
    color: "white"
});

const Aboutus = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#contact-form') {
            const scrollToContactForm = () => {
                const element = document.getElementById('contact-form');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            };

            // Retry the scroll if the element is not immediately found
            const retryInterval = setInterval(() => {
                const element = document.getElementById('contact-form');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    clearInterval(retryInterval);
                }
            }, 100);

            // Clear interval after a reasonable time to prevent infinite loops
            setTimeout(() => clearInterval(retryInterval), 5000);

            // Initial attempt to scroll
            scrollToContactForm();
        }
    }, [location]);

    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        from_number: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        from_name: false,
        from_email: false,
        from_number: false,
        message: false,
    });

    const [alert, setAlert] = useState({
        open: false,
        severity: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({ ...errors, [name]: false });
    };

    const templateParams = {
        from_name: formData.from_name,
        from_email: formData.from_email,
        to_name: "Natulatech",
        from_number: formData.from_number,
        message: formData.message
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {
            from_name: !formData.from_name.trim(),
            from_email: !formData.from_email.trim(),
            from_number: !formData.from_number.trim(),
            message: !formData.message.trim(),
        };

        setErrors(newErrors);

        const hasErrors = Object.values(newErrors).some(error => error);

        if (hasErrors) {
            setAlert({ open: true, severity: 'error', message: 'Please fill out all required fields.' });
        } else {
            emailjs.send('service_vmmucpa', 'template_nhw580k', templateParams, '9Fa4uotZA0oshYQh4')
                .then((result) => {
                    console.log(result.text);
                    setAlert({
                        open: true,
                        severity: 'success',
                        message: 'Message sent successfully!!! Our Team will reach out to you...'
                    });
                    setFormData({ from_name: "", from_email: "", from_number: "", message: "" })
                }, (error) => {
                    console.log(error.text);
                    setAlert({
                        open: true,
                        severity: 'error',
                        message: 'Error sending message'
                    });
                });
        }

    };

    return (
        <>
            <Background>
                <Grid container spacing={2}>
                    <Box display="flex" alignItems="center">
                        <Grid item xs={12} md={6} sx={{ borderRight: "2px solid white", height: "100%" }}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box
                                    component="img"
                                    src="https://res.cloudinary.com/drbprhsh8/image/upload/v1716435975/samples/Nat%20Logos/NSPL_Logos/NSPL_Logo_White_HD_zdb2kf.png"
                                    alt="natulatech-logo"
                                    sx={{
                                        width: {
                                            xs: '150px', // width for extra-small and small screens
                                            sm: '200px', // width for small screens
                                            md: '250px', // width for medium screens
                                            lg: '300px', // width for large screens and above
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ padding: "40px" }}>
                            <Typography sx={{ fontSize: { xs: "15px", md: "25px" }, color: "white" }} variant="overline" display="block" gutterBottom>
                                ABOUT US
                            </Typography>
                            <Typography sx={{ fontSize: { xs: "20px", md: "50px" }, color: "white" }} variant="h3" gutterBottom>
                                Natulatech Solutions <br /> - Innovating with Nature, Engineering the Future.
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>
            </Background>
            <Background>
                <Box sx={{
                    backgroundColor: '#cdd4cf',
                    padding: '10px',
                    textAlign: 'center',
                }}>
                    <Typography variant="h4" component="div" gutterBottom sx={{ fontSize: { xs: "25px", md: "35px" } }}>
                        Our Partners
                    </Typography>
                    <Box display="flex" justifyContent="space-around" alignItems="center" >
                        <Box
                            component="img"
                            src="https://res.cloudinary.com/digfyljcv/image/upload/v1716308982/partners/Biomimicry_Institute_pzpqsq.png"
                            alt="BIOMIMICRY"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: '50px', md: '110px' },
                                objectFit: 'contain',
                            }}
                        />
                        <Box
                            component="img"
                            src="https://res.cloudinary.com/digfyljcv/image/upload/v1718031494/nrf_logo_fa-logotype-removebg-preview_o0qajq.png"
                            alt="NRF-logo"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: '25px', md: '65px' },
                                objectFit: 'contain',
                            }}
                        />

                        <Box
                            component="img"
                            src="https://res.cloudinary.com/digfyljcv/image/upload/v1717861422/partners/gyss-logo-removebg-preview_r83apl.png"
                            alt="GYSS"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: '50px', md: '110px' },
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-around" alignItems="center" mt={4}>
                        <Box
                            component="img"
                            src="https://res.cloudinary.com/digfyljcv/image/upload/v1716308980/partners/Niti_Atal_pauu0l.png"
                            alt="NITI&AIM"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: '30px', md: '70px' },
                                objectFit: 'contain',

                            }}
                        />
                        <Box
                            component="img"
                            src="https://res.cloudinary.com/digfyljcv/image/upload/v1716308977/partners/BITS_Pilani_Logo_ri1jsb.png"
                            alt="BITS"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: '50px', md: '110px' },
                                objectFit: 'contain',
                            }}
                        />
                        <Box
                            component="img"
                            src="https://res.cloudinary.com/digfyljcv/image/upload/v1716308967/partners/SNU_mmyett.png"
                            alt="SHIV UNI"
                            sx={{
                                width: "100%",
                                maxHeight: { xs: '30px', md: '70px' },
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{ padding: "20px" }}>
                    <Grid item xs={12} mt={2} >
                        <Typography variant='h5' sx={{ fontSize: { xs: "20", md: "25" }, color: "white", marginBottom: "20px" }}>
                            The Future Isn't Just Happening. We're Building It.
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: { xs: "15", md: "20" }, color: "white" }}>
                            Ever stared at a leaf and thought, “There’s a blueprint for better tech”? We have. Every. Single. Day. At Natulatech Solutions, we're rewriting the rulebook on innovation by merging the elegance of nature with cutting-edge technology. It’s not just about being ahead of the curve; it’s about drawing a new curve entirely – one that embraces the world we live in and the future we want to see.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Typography variant='h5' sx={{ fontSize: { xs: "20", md: "25" }, color: "white", marginBottom: "20px" }} >
                            Our Philosophy: Think Like Nature, Act Like Innovators
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: { xs: "15", md: "20" }, color: "white" }}>
                            We believe that if you want to truly innovate, you need to go back to the roots. Literally. Our nature-inspired technologies are all about taking cues from the natural world to solve the complex challenges industries face today. From the micro to the macro, we're diving deep into the organic genius that’s been evolving for billions of years and using it to shape tomorrow’s tech landscape.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Typography variant='h5' sx={{ fontSize: { xs: "20", md: "25" }, color: "white", marginBottom: "20px" }} >
                            Why Nature? Because It Works.
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: { xs: "15", md: "20" }, color: "white" }}>
                            Think about it. Nature’s been at this a lot longer than we have. It’s perfected efficiency, sustainability, and resilience. Our job at Natulatech is to decode those secrets and translate them into technologies that don’t just push boundaries but leapfrog them.<br />
                            Here’s how we’re doing it:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon fontSize="small" sx={{ color: 'white' }} />
                                </ListItemIcon>
                                <Typography variant="body1" sx={{ fontSize: { xs: "15", md: "20" }, color: "white" }}>
                                    <strong>Biomimicry in Action:</strong> Whether it's replicating the aerodynamics of bird flight for more efficient drones or mimicking photosynthesis for cleaner energy, we look to the natural world for the answers.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon fontSize="small" sx={{ color: 'white' }} />
                                </ListItemIcon>
                                <Typography variant="body1" sx={{ fontSize: { xs: "15", md: "20" }, color: "white" }}>
                                    <strong>Eco-Innovations:</strong> We're not just making green tech. We're making tech that grows green. Imagine products that not only minimize harm but actively heal our planet.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon fontSize="small" sx={{ color: 'white' }} />
                                </ListItemIcon>
                                <Typography variant="body1" sx={{ fontSize: { xs: "15", md: "20" }, color: "white" }}>
                                    <strong>Smart Sustainability:</strong> Our solutions are designed to advance critical technologies without the trade-off of environmental degradation. Think smarter, not harder.
                                </Typography>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Typography variant='h5' sx={{ fontSize: { xs: "20", md: "25" }, color: "white", marginBottom: "10px" }} >
                            Committed to Exponential Change
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: { xs: "15", md: "20" }, color: "white" }}>
                            Incremental change is so yesterday. We're all about the exponential. The pace at which we're advancing critical technologies isn’t just fast—it’s accelerating. The faster we innovate, the quicker we can solve the pressing challenges of our time. And while we’re hurtling towards the future, we're making sure it’s a future worth living in.
                        </Typography>
                    </Grid>
                </Box>
            </Background>
            <Box sx={{
                backgroundColor: '#131924',
            }}>
                <Typography variant="h3" sx={{ fontSize: { xs: "25px", md: "35px" }, color: "white", textAlign: "center", paddingTop: "20px" }}>Founders</Typography>
                <Box display="flex" justifyContent="space-around" alignItems="center" sx={{ borderBottom: "1px solid white" }}>
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Box
                            component="img"
                            src="https://res.cloudinary.com/digfyljcv/image/upload/v1717845342/website%20pics/Abhay_pic-Photoroom_zyea7y.png"
                            alt="abhay-pic"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: '200px', md: '300px' },
                                objectFit: 'contain',
                            }}
                        />
                        <p style={{ color: "white" }}>Co-Founder and CEO</p>
                        <p style={{ color: "white" }}>Abhay Chakra Sadineni</p>
                        <Link href="https://www.linkedin.com/in/abhaychakra/" target="_blank" rel="noopener" sx={{ mt: 2 }}>
                            <IconButton color="primary">
                                <LinkedInIcon sx={{ fontSize: "35px" }} />
                            </IconButton>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Box
                            component="img"
                            src="https://res.cloudinary.com/digfyljcv/image/upload/v1717845130/website%20pics/Jayanth_2024_Passport_size-removebg-preview_fpduzr.png"
                            alt="jayanth-pic"
                            sx={{
                                width: '100%',
                                maxHeight: { xs: '200px', md: '300px' },
                                objectFit: 'contain',
                            }}
                        />
                        <p style={{ color: "white" }}>Co-Founder and CTO</p>
                        <p style={{ color: "white" }}>Jayanth Ivvala</p>
                        <Link href="https://www.linkedin.com/in/ijayanth/" target="_blank" rel="noopener" sx={{ mt: 2 }}>
                            <IconButton color="primary">
                                <LinkedInIcon sx={{ fontSize: "35px" }} />
                            </IconButton>
                        </Link>
                    </Grid>
                </Box>
                <Box p={3} mt={2} id="contact-form">
                    <Typography variant="h4" component="h1" gutterBottom sx={{ fontSize: { xs: "20px", md: "35px" }, color: "white", marginBottom: "15px" }}>
                        Contact Us
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                        <StyledTextField
                            required
                            id="full-name"
                            label="Full Name"
                            name="from_name"
                            value={formData.from_name}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'white' } }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                    },
                                    '& input': {
                                        color: 'white',
                                    },
                                },
                            }}
                        />
                        <StyledTextField
                            required
                            id="email"
                            label="Email"
                            type="email"
                            name="from_email"
                            value={formData.from_email}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'white' } }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                    },
                                    '& input': {
                                        color: 'white',
                                    },
                                },
                            }}
                        />
                        <StyledTextField
                            required
                            id="phone"
                            label="Phone Number"
                            type="tel"
                            name="from_number"
                            value={formData.from_number}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ style: { color: 'white' } }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                    },
                                    '& input': {
                                        color: 'white',
                                    },
                                },
                            }}
                        />
                        <StyledTextField
                            required
                            id="message"
                            label="Message"
                            variant="outlined"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            multiline
                            rows={4}
                            InputLabelProps={{ style: { color: 'white' } }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                    },
                                    '& textarea': {
                                        color: 'white',
                                    },
                                },
                            }}
                        />
                        <Button variant="contained" color="primary" type="submit" sx={{
                            marginBottom: "15px", '&:hover': {
                                backgroundColor: "darkgreen",
                            },
                        }}>
                            Submit
                        </Button>
                        <Collapse in={alert.open}>
                            <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, open: false })}>
                                {alert.message}
                            </Alert>
                        </Collapse>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default Aboutus