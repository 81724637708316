import React from 'react'
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


const Background = styled('div')({
    backgroundImage: 'url(https://res.cloudinary.com/digfyljcv/image/upload/v1716309228/website%20pics/Bloom_BG_yescvh.png)', // Set the path to your background image
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "center",
    height: '100%',
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Adjust the color and opacity as needed
        zIndex: 1,
    },
    '> *': {
        position: 'relative',
        zIndex: 2,
    },
});

const MarqueeContainer = styled(Box)({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor:"green",
    position:"relative"
});

const MarqueeText = styled(Typography)({
    display: 'inline-block',
    color: "white",
    animation: 'marquee 15s linear infinite',
    '@keyframes marquee': {
        '0%': { transform: 'translateX(100%)' },
        '100%': { transform: 'translateX(-100%)' },
    },
});

const Technology = () => {

    return (
        <>
            <Background>
                <MarqueeContainer>
                    <MarqueeText variant="h6">Our product line is now available through an independent, decentralized division. Please contact us to place your order.</MarqueeText>
                </MarqueeContainer>
                <Box id="firstSection" sx={{ display: 'flex', flexDirection: { xs: "column", md: "row" }, height: "110vh", padding: 0 }}>
                    <Box
                        sx={{
                            flex: 1,
                            borderRight: '1px solid black',
                            padding: 3,
                            order: { xs: 1, md: 1 }
                        }}
                    >
                        <Typography sx={{ fontSize: { xs: "20px", md: "30px" }, color: "white" }} variant="h4" gutterBottom>
                            Welcome to the Future of Liquid Purity
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, color: "white", marginBottom: "10px" }} variant="body1">
                            Ever wondered if the milk you're drinking is as pure as it should be? At Natulatech Solutions, we’ve developed Lotuster, an omni-liquid adulterant detection device that’s revolutionizing the way we ensure the quality and purity of our liquids. Portable, affordable, and incredibly precise, Lotuster is here to transform quality control across industries, especially for dairy farmers.
                        </Typography>
                        <Box display="flex" justifyContent="center">
                            <Box
                                component="img"
                                src="https://res.cloudinary.com/digfyljcv/image/upload/v1718031693/website%20pics/Lotuster_design_gfxfao.png"
                                alt="lotuster-pic"
                                sx={{
                                    maxHeight: { xs: '150px', md: '250px' },
                                    objectFit: 'contain',
                                    marginBottom: "10px",
                                    borderRadius: "5%",
                                    marginLeft: "-10px"
                                }}
                            />
                        </Box>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, color: "white", marginBottom: "10px" }} variant="body1">
                            Patent (IP India): Device, Method, and Substance for Detection of Adulteration in Liquids.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            width: { xs: "89%", md: "50%" },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            padding: 3,
                            backgroundColor: "#cedbd7",
                            overflowY: "auto",
                            transition: 0.3,
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            msOverflowStyle: 'none', // IE and Edge
                            scrollbarWidth: 'none', // Firefox
                            order: { xs: 2, md: 1 }
                        }}
                    >
                        <Typography sx={{ fontSize: { xs: "20px", md: "30px" } }} variant="h4" gutterBottom>
                            What is Lotuster®?
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1">
                            Lotuster is a cutting-edge device inspired by the pristine properties of the lotus leaf. Just as the lotus leaf repels impurities, Lotuster detects them with unmatched accuracy. Whether you're a dairy farmer, a food and beverage producer, or a health-conscious consumer, Lotuster empowers you to verify liquid purity instantly and effortlessly.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            The Magic of Omni-Liquid Detection
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>Multi-purpose:</strong> From milk and edible oils to honey, Lotuster can test virtually any liquid for adulterants, contaminants, and impurities.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>Portable Power:</strong> Small enough to fit in your pocket, Lotuster is designed for on-the-go testing. Take it to the farm, the factory, or the grocery store—quality assurance is now at your fingertips.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>Cost-Effective:</strong> By dramatically reducing the cost of quality control by 99.6%, Lotuster makes state-of-the-art testing accessible to everyone, not just large corporations.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} variant="h6" gutterBottom>
                            Benefits for Dairy Farmers
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>Enhanced Trust:</strong> With Lotuster, you can provide verifiable proof of milk purity to your customers, building stronger relationships and brand loyalty.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>Reduced Costs:</strong> Traditional lab tests are expensive and time-consuming. Lotuster slashes these costs, making high-quality testing affordable for even small-scale farmers.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>Improved Efficiency:</strong> Quickly identify and rectify issues, reducing waste and improving overall productivity.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            We have introduced LCA (Life Cycle Assessment) to make dairy farming sustainable.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} variant="h6" gutterBottom>
                            Ready to Boost Quality Control?
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            Take the next step in ensuring liquid purity with Lotuster. Secure your license today and bring state-of-the-art adulterant detection to your operation.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            Connect with us to get your Lotuster license.
                        </Typography>
                    </Box>
                </Box>
            </Background>
            <Background>
                <Box id="secondSection" sx={{ display: 'flex', flexDirection: { xs: "column", md: "row" }, height: "125vh", padding: 0 }}>
                    <Box
                        sx={{
                            flex: 1,
                            width: { xs: "89%", md: "50%" },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            padding: 3,
                            backgroundColor: "#cedbd7",
                            overflowY: "auto",
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            msOverflowStyle: 'none', // IE and Edge
                            scrollbarWidth: 'none', // Firefox
                            order: { xs: 2, md: 1 }
                        }}
                    >
                        <Typography sx={{ fontSize: { xs: "20px", md: "30px" }, marginTop: "10px" }} variant="h4" gutterBottom>
                            Understanding Deep Vein Thrombosis
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1">
                            Deep Vein Thrombosis occurs when a blood clot forms in a deep vein, usually in the legs. These clots can cause pain, swelling, and in severe cases, can travel to the lungs, resulting in a pulmonary embolism. Traditional treatments involve anticoagulant medications, compression stockings, and sometimes invasive procedures. However, these methods often come with significant side effects and limitations.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            How Biomimetic systems Work
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "10px" }} variant="body1">
                            Our systems are designed to:
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>Regulate Blood Flow:</strong> Just like the natural valves in your veins, our systems ensure that blood flows in the correct direction and at the optimal rate, reducing the risk of clot formation.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>Enhance Circulation:</strong> Enhance Circulation: By improving circulation, these devices help to prevent blood from pooling in the lower extremities, a common precursor to DVT.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} variant="h6" gutterBottom>
                            Benefits of Biomimetic systems for DVT
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>1. Precision and Efficiency:</strong> Nature’s designs are inherently efficient. By mimicking these designs, our systems offer precise control over blood flow, ensuring that the risk of clot formation is minimized without the need for continuous external power or frequent adjustments.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant="body1" paragraph>
                            <strong>2. Reduced Side Effects:</strong> Current DVT treatments often come with a host of side effects, from bleeding risks associated with anticoagulants to discomfort from compression garments. Our biomimetic systems offer a safer alternative, reducing the dependency on medication and invasive procedures.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, paddingBottom: "15px" }} variant="body1" paragraph>
                            <strong>3. Continuous Protection:</strong> Once integrated, our systems work continuously to monitor and manage blood flow. This ongoing protection is crucial for patients at high risk of DVT, providing peace of mind and a better quality of life.

                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            borderLeft: '1px solid black',
                            padding: 3,
                            borderTop: "3px solid black",
                            order: { xs: 1, md: 1 },

                        }}
                    >
                        <Typography sx={{ fontSize: { xs: "20px", md: "30px" }, color: "white" }} variant="h4" gutterBottom>
                            Reimagining Healthcare Solutions - Solving Deep Vein Thrombosis
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, color: "white", marginBottom: "15px" }} variant="body1">
                            Deep Vein Thrombosis (DVT) is a serious condition that can lead to life-threatening complications. At Natulatech Solutions, we’re leveraging the power of biomimetic systems to revolutionize the prevention and treatment of DVT. Inspired by nature’s efficiency, these innovative devices are designed to enhance patient outcomes by mimicking the natural flow control mechanisms found in biological systems.
                        </Typography>
                        <Box mt={3} display="flex" justifyContent="space-around" alignItems="center" sx={{ flexDirection: { xs: "column", md: "row" }, marginLeft: "-10px" }}>
                            <Box
                                component="img"
                                src="https://res.cloudinary.com/digfyljcv/image/upload/v1718153855/Health%20pics/Deep_Vein_Thrombosis_cklgug.jpg"
                                alt="lotuster-pic"
                                sx={{
                                    maxHeight: { xs: '160px', md: '180px' },
                                    maxWidth: { xs: '80%', md: '100%' },
                                    objectFit: { xs: "cover", md: "contain" },
                                    borderRadius: "5%",
                                }}
                            />
                            <Box
                                component="img"
                                src="https://res.cloudinary.com/digfyljcv/image/upload/v1718153851/Health%20pics/deep-thrombosis_b5b4bn.jpg"
                                alt="lotuster-pic"
                                sx={{
                                    maxHeight: { xs: '150px', md: '180px' },
                                    maxWidth: { xs: '80%', md: '100%' },
                                    objectFit: { xs: "cover", md: "contain" },
                                    borderRadius: "5%",
                                    marginTop: { xs: "15px", md: "0px" }
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Background>
            <Background>
                <Box id="thirdSection" sx={{ display: 'flex', flexDirection: { xs: "column", md: "row" }, height: "105vh", padding: 0 }}>
                    <Box sx={{
                        flex: 1,
                        width: { xs: "89%", md: "50%" },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        padding: 3,
                        backgroundColor: "#cedbd7",
                        overflowY: "auto",
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        msOverflowStyle: 'none', // IE and Edge
                        scrollbarWidth: 'none', // Firefox
                        order: { xs: 2, md: 2 }
                    }}
                    >
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            <strong>How It Works</strong>
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "10px" }} variant="body1">
                            Inspired by the efficient natural processes observed in living organisms, our lab-on-chip technology employs microfluidic channels to perform continuous flow polymerase chain reaction (PCR) for DNA amplification. This innovative approach enables rapid and precise DNA amplification, making it ideal for point-of-care diagnostics.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            <strong>Biomimetic Precision</strong>
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "10px" }} variant="body1">
                            Our device utilizes the principles of biomimetics to mimic the natural fluid dynamics found in biological systems. By incorporating these principles, we achieve highly efficient and reliable DNA amplification. The microfluidic continuous flow system ensures consistent and controlled reactions, leading to accurate and reproducible results.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            <strong>On-Site and Real-Time</strong>
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "10px" }} variant="body1">
                            The true power of our technology lies in its ability to deliver on-site diagnostics. Traditional PCR methods require extensive lab facilities and time-consuming processes. In contrast, our portable lab-on-chip device brings the laboratory to the point of care, enabling immediate DNA amplification and detection. This capability is crucial for rapid decision-making in clinical settings, field diagnostics, and remote locations.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            <strong>Comprehensive Detection</strong>
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "10px" }} variant="body1">
                            Our microfluidic PCR device is not just about amplification; it’s about complete diagnostic solutions. The integrated detection system ensures that amplified DNA can be analyzed in real-time, providing comprehensive insights without the need for additional equipment or steps. This seamless integration enhances efficiency and accuracy, making it a game-changer in point-of-care diagnostics.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            <strong>Real-World Applications</strong>
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "10px" }} variant="body1">
                            By enabling on-site DNA amplification and detection, our technology has far-reaching applications. From infectious disease detection and genetic testing to environmental monitoring and agricultural diagnostics, the possibilities are vast. Healthcare providers can deliver faster diagnoses, researchers can conduct field studies with ease, and agricultural professionals can monitor crop health more effectively.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            borderLeft: '1px solid black',
                            padding: 3,
                            borderTop: "3px solid black",
                            order: { xs: 1, md: 1 }
                        }}

                    >
                        <Typography sx={{ fontSize: { xs: "20px", md: "30px" }, color: "white" }} variant="h4" gutterBottom>
                            Advancing Point-of-Care Diagnostics with Biomimetic Technology
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, color: "white", marginBottom: "10px" }} variant="body1">
                            Imagine a world where diagnosing diseases and detecting genetic markers is as simple as a quick test on-site.
                        </Typography>
                        <Box display="flex" justifyContent="center">
                            <Box
                                component="img"
                                src="https://res.cloudinary.com/digfyljcv/image/upload/c_crop,ar_1:1/v1718153853/Health%20pics/Digital-Biomarkers-1920x880_yq8dkm.jpg"
                                alt="lotuster-pic"
                                sx={{
                                    borderRadius: "5%",
                                    maxHeight: { xs: '200px', md: '300px' },
                                    objectFit: 'contain',
                                    marginTop: "30px",
                                    marginLeft: { xs: "-10px" }
                                }}
                            />
                        </Box>

                    </Box>
                </Box>
            </Background>
            <Background>
                <Box id="fourthSection" sx={{ display: 'flex', flexDirection: { xs: "column", md: "row" }, height: "110vh", padding: 0 }}>
                    <Box
                        sx={{
                            flex: 1,
                            width: { xs: "89%", md: "50%" },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            padding: 3,
                            backgroundColor: "#cedbd7",
                            overflowY: "auto",
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            msOverflowStyle: 'none', // IE and Edge
                            scrollbarWidth: 'none', // Firefox
                            order: { xs: 2, md: 1 }
                        }}
                    >
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            <strong>How It Works</strong>
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "10px" }} variant="body1">
                            Our systems are engineered to transport water directionally without the need for external power sources. By leveraging natural principles of capillarity and surface tension, these devices ensure that water flows efficiently in one direction, preventing wastage and maximizing collection. This technology is particularly suited for semi-arid and arid regions where every drop counts.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            <strong>Sustainable and Scalable</strong>
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "10px" }} variant="body1">
                            The beauty of our biomimetic approach lies in its sustainability. Our systems are created using eco-friendly methods and materials, often derived from agricultural waste. This not only reduces environmental impact but also promotes a circular economy. Moreover, our biomimetic systems are scalable, making them suitable for small communities and large-scale agricultural operations alike.
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "18px", md: "25px" } }} mt={2} variant="h6" gutterBottom>
                            <strong>Real-World Impact</strong>
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "10px" }} variant="body1">
                            By increasing water access in semi-arid and arid regions, we are helping to transform lives. Our technology supports agriculture, reduces dependency on unpredictable rainfalls, and provides a reliable water source for communities. This, in turn, fosters economic growth, enhances food security, and improves overall quality of life.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            borderRight: '1px solid black',
                            padding: 3,
                            borderTop: "3px solid black",
                            order: { xs: 1, md: 2 }
                        }}
                    >
                        <Typography sx={{ fontSize: { xs: "20px", md: "30px" }, color: "white" }} variant="h4" gutterBottom>
                            Advancing Water Access in Arid Regions with Nature-Inspired Technologies
                        </Typography>
                        <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, color: "white", marginBottom: "10px" }} variant="body1">
                            Inspired by the natural water transport systems of xylem vessels in plants, our systems are designed to mimic the efficiency and directionality of nature’s own water movers. These innovative devices are not just about moving water; they are about doing so with unparalleled precision and minimal energy consumption.
                        </Typography>
                        <Box display="flex" justifyContent="center">
                            <Box
                                component="img"
                                src="https://res.cloudinary.com/digfyljcv/image/upload/v1718153852/Health%20pics/file.PostFileLoader-1_hztfaq.jpg"
                                alt="lotuster-pic"
                                sx={{
                                    borderRadius: "5%",
                                    maxHeight: { xs: '200px', md: '300px' },
                                    objectFit: 'contain',
                                    marginTop: "30px",
                                    marginLeft: { xs: "-10px" }
                                }}
                            />
                        </Box>

                    </Box>
                </Box>
            </Background>
        </>
    )
}

export default Technology