import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Grid, Alert, Collapse  } from '@mui/material';
import emailjs from '@emailjs/browser'

const Footer = () => {
    const [email, setEmail] = useState('')

    const [alert, setAlert] = useState({
        open: false,
        severity: '',
        message: ''
    });

    const templateParams = {
        from_email: email,
        to_name: "Natulatech"
    }

    const handleChange = (e) => {
        setEmail(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email) {
            setAlert({
                open: true,
                severity: 'error',
                message: 'Email ID is required'
            });
            return;
        }else if(!emailRegex.test(email)){
            setAlert({
                open: true,
                severity: 'error',
                message: 'Invalid Email ID'
            });
            return;
        }

        emailjs.send('service_vmmucpa', 'template_bpo004m', templateParams, '9Fa4uotZA0oshYQh4')
            .then((result) => {
            
                setAlert({
                    open: true,
                    severity: 'success',
                    message: 'Signup request sent successfully!!!'
                });
                setEmail('')
            }, (error) => {

                setAlert({
                    open: true,
                    severity: 'error',
                    message: 'Error sending request'
                });
            });
    };
    return (
        <Container maxWidth="xl" style={{ backgroundColor: '#B0B4B8', padding: '40px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography sx={{fontSize:{xs:"20px",md:"25px"}}} variant="h6" gutterBottom>
                            NATULATECH NEWSLETTER
                        </Typography>
                        <img width="80px" src='https://res.cloudinary.com/drbprhsh8/image/upload/v1716435975/samples/Nat%20Logos/NSPL_Logos/NSPL_Logo_Black_kpvylt.png' alt="natulatech-logo" />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{ fontSize: { xs: "16px", md: "35px" } }} gutterBottom>
                        Sign up to stay in the loop with the <br /> latest updates from Natulatech Solutions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection:{xs:"column", sm:"row"}, justifyContent: "space-between", alignItems: "flex-start" }}>
                        <Grid item xs={12} sm={6}>
                            <Box component="form" onSubmit={handleSubmit} display="flex" alignItems="center" mb={2}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Email"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                    type='email'
                                    fullWidth
                                    InputLabelProps={{ style: { color: 'white' } }}
                                    style={{ marginRight: '8px', backgroundColor: '#B0B4B8' }}
                                />
                                <Button variant="contained" type="submit" sx={{ color: "white", backgroundColor: "#0D222A", width: "150px", height: "60px" }}>
                                    SIGN UP
                                </Button>
                            </Box>
                            <Collapse in={alert.open}>
                                <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, open: false })}>
                                    {alert.message}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box textAlign="right">
                                <Typography variant="body1">
                                    contact@natulatech.com
                                </Typography>
                                <Typography variant="body1">
                                    Natulatech Solutions &copy; All Rights Reserved
                                </Typography>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Footer;
