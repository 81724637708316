import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, MenuItem, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';

const NavBarContainer = styled(AppBar)({
  backgroundColor: 'transparent',
  backdropFilter: 'blur(15px)', // Apply a blur effect
  '-webkit-backdrop-filter': 'blur(15px)', // For Safari
  borderBottom: '1px solid white',
  position: "relative",
  zIndex: 3,
});

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: "white",
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));


const NavLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const MobileMenu = styled('div')(({ theme }) => ({
  display: 'flex',
  color: 'white',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const MenuLink = styled(Typography)(({ theme }) => ({
  textDecoration: 'none',
  color: 'white',
  marginRight: theme.spacing(2),
  paddingRight: "12px",
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: '2px', // Adjust the width of the border
    backgroundColor: 'white', // Adjust the color of the border
  },
  '&:last-child::after': {
    display: 'none', // Hide the border for the last MenuLink
  },
}));

const Background = styled('div')({
  backgroundImage: 'url(https://res.cloudinary.com/digfyljcv/image/upload/v1716116394/website%20pics/Designer_cbcdhi.png)', // Set the path to your background image
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: "center",
  height: '100%',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the color and opacity as needed
    zIndex: 1,
  },
  '> *': {
    position: 'relative',
    zIndex: 2,
  },
});

const StyledMenuItem = styled(MenuItem)({
  borderBottom: '1px solid white', // Add border line at the bottom of each MenuItem
});

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50px',
  '& img': {
    height: '100%',
    width: 'auto',
  },
});

const Title = styled(Typography)({
  marginLeft: '10px', // Adjust the spacing between the image and the title
  color: 'white',
  textDecoration:"none"
});

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenu = () => {
    setMobileMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setMobileMenuOpen(false);
  };

  return (
    <Background>
    <NavBarContainer position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ImageContainer component="a" href="/home">
            <img src="https://res.cloudinary.com/drbprhsh8/image/upload/v1716435975/samples/Nat%20Logos/NSPL_Logos/NSPL_Logo_White_HD_zdb2kf.png" alt="Natulatech Logo" />
          </ImageContainer>
          <Title variant="h6" component="a" href="/home">
            Natulatech
          </Title>
        </div>
        <NavLinks>
          <MenuLink component="a" href="/technology">Technology</MenuLink>
          <MenuLink component="a" href="/careers">Careers</MenuLink>
          <MenuLink component="a" href="/aboutus">About us</MenuLink>
        </NavLinks>
        <MenuButton edge="start" aria-label="menu" onClick={handleMenu}>
          {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </MenuButton>
      </Toolbar>
      <Collapse in={mobileMenuOpen}>
        <MobileMenu>
          <StyledMenuItem onClick={handleClose} component="a" href="/technology">Technology</StyledMenuItem>
          <StyledMenuItem onClick={handleClose} component="a" href="/careers">Careers</StyledMenuItem>
          <StyledMenuItem onClick={handleClose} component="a" href="/aboutus">About us</StyledMenuItem>
        </MobileMenu>
      </Collapse>
    </NavBarContainer>
    </Background>
  );
};

export default NavBar;
