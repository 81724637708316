// src/components/BlurredBoxWithGrids.js
import React, { useState } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const BlurredBox = styled(Box)({
    backgroundColor: '#0D222A',
    backdropFilter: 'blur(5px)',
    minHeight: '100vh',
    borderTop: "1px solid white",
    borderBottom: "1px solid white",
    display: "flex",
});

const NucleusContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '200px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        width: '150px',
        height: '150px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100px',
        height: '100px',
    },
}));

const NucleusText = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '80px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: '30px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('md')]: {
        width: '70px',
        height: '70px',
        fontSize: '25px'
    }
}));


const Atom = styled('div')(({ angle, radius, color, theme }) => ({
    position: 'absolute',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '25px', // Adjust font size as needed
    color: 'black',
    top: `calc(50% - ${radius}px * sin(${angle}deg))`,
    left: `calc(50% + ${radius}px * cos(${angle}deg))`,
    transform: `translate(-50%, -50%)`,
    animation: `revolve 10s linear infinite`,
    animationDelay: `${angle / 360 * 10}s`,
    [theme.breakpoints.down('md')]: {
        fontSize: '25px',
        top: `calc(50% - ${radius * 0.75}px * sin(${angle}deg))`,
        left: `calc(50% + ${radius * 0.75}px * cos(${angle}deg))`,
    },
    [theme.breakpoints.down('sm')]: {
        width: '45px',
        height: '45px',
        fontSize: '20px',
        top: `calc(50% - ${radius * 0.4}px * sin(${angle}deg))`,
        left: `calc(50% + ${radius * 0.4}px * cos(${angle}deg))`,
    },
    background: `radial-gradient(circle at 30% 30%, white, ${color})`,
    boxShadow: `inset -5px -5px 15px rgba(0,0,0,0.3), inset 5px 5px 15px rgba(255,255,255,0.5), 0 4px 6px rgba(0, 0, 0, 0.3)`,
    '@keyframes revolve': {
        '0%': {
            transform: `rotate(${angle}deg) translate(${radius}px) rotate(-${angle}deg)`,
        },
        '100%': {
            transform: `rotate(${angle + 360}deg) translate(${radius}px) rotate(-${angle + 360}deg)`,
        },
    },
    '&:hover': {
        cursor: 'pointer',
        background: `radial-gradient(circle at 30% 30%, #00308f, #00308f)`,
        color: "white"
    },
}));


const NumberButton = styled(Button)(({ theme, isHovered }) => ({
    position: 'relative',
    aspectRatio: '1 / 1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid white',
    width: '50px',
    height: '50px',
    color: "white",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    backgroundColor: isHovered ? "#00308f" : "transparent",
    boxShadow: isHovered ? "2px 2px 5px white" : "none",
    '&:hover': {
        backgroundColor: "#00308f",
        boxShadow: "2px 2px 5px white",
    },
}));

const BoxComponent = () => {
    const [selectedAtom, setSelectedAtom] = useState("I");
    const [hoveredAtom, setHoveredAtom] = useState("");


    const handleAtomClick = (atom) => {
        setSelectedAtom(atom);
    };

    const handleAtomHover = (atom) => {
        setHoveredAtom(atom);
    };

    const handleAtomLeave = () => {
        setHoveredAtom("");
    };

    const atomContent = hoveredAtom || selectedAtom;
    return (
        <BlurredBox>
            <Grid container>
                <Grid sx={{
                    borderRight: { md: '1px solid white' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} item xs={12} md={6}>
                    <NucleusContainer>
                        <NucleusText>Our Philosophy</NucleusText>
                        {["I", "I I", "I I I"].map((num, index) => (
                            <Atom
                                key={num}
                                angle={index * 120}
                                radius={100}
                                color='white'
                                onMouseEnter={() => handleAtomHover(num)}
                                onMouseLeave={handleAtomLeave}
                                onClick={() => handleAtomClick(num)}
                            >
                                {num}
                            </Atom>
                        ))}
                    </NucleusContainer>
                </Grid>
                <Grid sx={{
                    p: 3,
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start"
                }} item xs={12} md={6}>
                    <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, marginBottom: "20px" }} variant='body1'>On a quest to confront pressing challenges and address silent epidemics</Typography>
                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ marginRight: "20px" }}>

                            {["I", "I I", "I I I"].map(num => (
                                <NumberButton 
                                    isHovered={hoveredAtom === num}
                                    key={num} 
                                    onClick={() => handleAtomClick(num)}
                                >
                                    <span>{num}</span>
                                </NumberButton>
                            ))}
                        </div>
                        <div>

                            {atomContent === "I" && (
                                <div >
                                    <Typography sx={{ fontSize: { xs: "35px", md: "50px" } }} variant='h2'>HEALTH</Typography>
                                    <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant='body1'>Addressing the toughest, long-standing challenges with cutting-edge biomedical innovations.</Typography>
                                </div>
                            )}
                            {atomContent === "I I" && (
                                <div>
                                    <Typography sx={{ fontSize: { xs: "35px", md: "50px" } }} variant='h2'>CLIMATE</Typography>
                                    <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant='body1'>Environmental stewardship fuels our innovation. We reject myopic solutions that heat our planet, pioneering products with zero carbon footprint for a truly sustainable future.</Typography>
                                </div>
                            )}
                            {atomContent === "I I I" && (
                                <div>
                                    <Typography sx={{ fontSize: { xs: "35px", md: "50px" } }} variant='h2'>NATURE</Typography>
                                    <Typography sx={{ fontSize: { xs: "15px", md: "20px" } }} variant='body1'>Rooted in the philosophy of Biomimicry, we build systems inspired by nature, effortlessly fulfilling our health and environmental goals.</Typography>
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </BlurredBox >
    );
};

export default BoxComponent;
